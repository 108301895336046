import React  from 'react';
import "./style.css";
import Menu from "./menuApi";
import { useState } from 'react';
import MenuCard from "./MenuCard";
import Navbar from './Navbar';


const uniqueList= [
  ...new Set(Menu.map((curElement)=>{
  return curElement.category
})
),"All"
];

const Resturant = () => {
  const [menuData, setMenuData]= useState(Menu);
  const [menuList, setMenuList]= useState(uniqueList);

  const filterItem=(category)=>{
    if(category==="All"){
      setMenuData(Menu);
      return;
    }
    const updatedList=Menu.filter((curElement)=>{
      return curElement.category===category;
    });
    setMenuData(updatedList);
  };
  return (
    <>
      <Navbar filterItem= {filterItem} menuList={menuList}/>
        <MenuCard menuData={menuData} />
    </>
  )
}

export default Resturant
