import React from 'react'
import Resturant from './component/Basics/Resturant';

const App = () => {
  return (
    <div>
      <Resturant />
      
    </div>
  )
}


export default App
 